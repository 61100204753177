import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { HelloBar } from 'components/store/HelloBar';
import { BorderBottom } from 'components/layout/BorderBottom';
import styles from './TopBarLupine.module.css';
import { useQueryParams } from 'hooks/location';

import StoreHeaderLupine from './StoreHeaderLupine';
import CustomShopChat from 'components/shop/CustomShopChat';

export function TopBarLupine({
  shop,
  headerVariant,
  showCart,
}) {

  const topBarRef = useRef();
  const params = useQueryParams();
  const chatOpenEnabled = params.has('openChat');

  return (
    <>
      <div ref={topBarRef} className={styles.topBar}>
        <HelloBar shop={shop}/>
        <div className={styles.topBarContainer}>
          <StoreHeaderLupine
            shop={shop}
            showCart={headerVariant === 'normal'}
            showSearch={headerVariant === 'normal'}
            showChat={headerVariant === 'normal'}
          />
        </div>
        <BorderBottom/>
      </div>
      <div id="topBarEnd"/>
      <div
        style={{ paddingTop: `${topBarRef?.current?.scrollHeight}px` }}
        className={styles.overflow}
      >
      </div>
      {showCart && chatOpenEnabled && (
        <CustomShopChat
          shopId={shop?._id}
          shop={{ name: shop?.name, }}
          slug={shop?.slug}
        />
      )}
    </>
  );

}

TopBarLupine.propTypes = {
  shop: PropTypes.object.isRequired,
  headerVariant: PropTypes.string,
  showStepper: PropTypes.bool,
  isDigitalShipping: PropTypes.bool
};

TopBarLupine.defaultProps = {
  catalogs: [],
  headerVariant: 'normal',
  showStepper: false,
  isDigitalShipping: false,
  showSearch: true,
  showCart: true
};
